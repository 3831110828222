












import Vue from 'vue';
import Component from 'vue-class-component';
import { Global } from '@/store';

@Component({})
export default class VideoGuides extends Vue {
    @Global.Mutation('setPageTitle') setPageTitle;
    @Global.State('lang') lang;

    he_video_ids = ['sW1849XHAiE', 'GVCgMXgW1C4', 'CZ5wI_B1lSs', 'gd_PxSzDHBM', 'k0RB9OQb5Ws', '1t13PQsl_Pw'];
    en_video_ids = ['ItgbmhblZZs', 'pM3tGX3C-FY', 'RFPrG3Ctb3g', '0qTtWMwJaMo', 'PfhkLlXvEnc', 'BoOvGu320iw'];

    video_title = [
      'Multiple selection function',
      'Creating a cabinet schedule',
      'Creating schedule using groups and creating new dimming curve',
      'Creating a new group',
      'Creating a new filter',
      'Change device location'
    ];

    mounted() {
      this.setPageTitle('Video Guides');
    }

    get video_height(){
      return this.$vuetify.breakpoint.lgAndUp ? 600 : 400;
    }
}
